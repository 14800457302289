<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2"       v-if='!isBusy'>
        <!-- Table Top -->
        <b-row lg="12" md="6" sm="12">

          <!-- Per Page -->
          <b-col

            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">{{ $t('labels.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.direction ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="mb-0">{{ $t('labels.rows') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex align-items-center justify-content-end"
              style="gap: 10px;"
            >
              <b-dropdown
                v-if="hasImportMulti || hasImport || hasExport || hasExportMulti"
                class="ml-1"
                :text="$t('Tools')"
                variant="secondary"
              >
                <b-dropdown-item-button v-if="hasImport">
                  <input
                      id="fileInput"
                      type="file"
                      hidden
                      accept=".xls, .xlsx"
                      @change="handleFileUpload($event, hasImport)"
                  >
                  <label
                      for="fileInput"
                      class="m-0"
                  >
                    <span>{{ $t('upload file') +' '+ $t('models.'+hasExport) }}</span>
                  </label>
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="hasExport" @click.prevent="downloadFileOnClick(hasExport)">
                  <span>{{ $t('download file') +' '+ $t('models.'+hasExport) }}</span>
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="hasImportMulti">
                  <input
                    :id="'fileInput_'+hasImportMulti"
                    type="file"
                    hidden
                    accept=".xls, .xlsx"
                    @change="handleFileUpload($event, hasImportMulti)"
                  >
                  <label
                    :for="'fileInput_'+hasImportMulti"
                  >
                    <span>{{ $t('upload file') +' '+ $t(hasImportMulti) }}</span>
                  </label>
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="hasExportMulti" @click.prevent="downloadFileOnClick(hasExportMulti)">
                  <span>{{ $t('download file') +' '+ $t(hasExportMulti) }}</span>
                </b-dropdown-item-button>
              </b-dropdown>
              <router-link
                v-if="generalActions.can_create"
                v-slot="{ navigate }"
                :to="addRoute"
                class="btn btn-primary"
                custom
              >
                <span
                  role="link"
                  @click="navigate"
                  @keypress.enter="navigate"
                >{{ addLabel }}</span>
              </router-link>
              <b-dropdown
                v-if="generalActions.can_import || generalActions.can_export"
                :right="!$store.state.appConfig.isRTL"
                class="ml-1"
                :text="$t('Tools')"
                variant="primary"
              >
                <b-dropdown-item v-if="generalActions.can_import">
                  <span class="align-middle ml-50">{{ $t('Import') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="generalActions.can_export"
                  @click.prevent="exportTable"
                >
                  <span class="align-middle ml-50">{{ $t('Export') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        responsive
        :items="items"
        :fields="fields"
        :sort-desc.sync="isSortDirDesc"
        :hover="true"
        show-empty
        :empty-text="$t('messages.there_is_no_data_to_display')"
        :busy='isBusy || TabBusy'
        class="mb-0"
      >
        <template #table-busy>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </template>
        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>

        <template #cell(image)="data">
          <b-avatar
            :src="data.item.image"
            rounded
            size="4rem"
          />
        </template>

        <template #cell(logo)="data">
          <b-avatar
            :src="data.item.logo"
            rounded
            size="4rem"
          />
        </template>
        <!-- Row: Status -->
        <template #cell(status_value)="data">
          <b-badge
            pill
            :variant="statusVariant(data.item.status)"
            class="text-capitalize"
          >
            {{ data.item.status_value }}
          </b-badge>
        </template>
        <!-- Row: Action -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <span>
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="(data.item.actions && data.item.actions.can_update)"
                    variant="link"
                    :to="{ name: editRoute, params: { resourceId: data.item.id } }"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('labels.edit') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="(data.item.actions && data.item.actions.can_delete)"
                    @click.prevent="showDeleteModal(data.item)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('labels.delete') }}</span>
                  </b-dropdown-item>
                  <slot
                    v-if="slotCustomActions"
                    name="custom_actions"
                    :item="data.item"
                    :generalActions="generalActions"
                  />
                </b-dropdown>
              </span>
            </span>
            <div
              v-if="(data.item.actions && data.item.actions.can_change_status) && canChangeStatus && !customChangeStatus"
              class="position-relative"
            >
              <b-form-checkbox
                v-model="data.item.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
              <div
                class="switch-layer"
                @click.prevent="changeStatus($event, data.item)"
              />
            </div>
            <div


              class="position-relative"  v-if="hasCompetition"
            >
              <span>{{$t('competition')}}</span>

              <b-form-checkbox
                v-model="data.item.has_competition"
                checked="true"
                value="true"
                unchecked-value="false"
                name="check-button"
                switch
              />
              <div
                class="switch-layer"
                @click.prevent="addCompetition($event, data.item)"
              />
            </div>

            <slot
                v-if="customChangeStatus"
                name="status_action"
                :item="data.item"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2" v-if='!isBusy'>
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('labels.total') }} <span class="text-capitalize">
              {{ $t('models.model_title', { title: $t(`models.${resourceName}`) }) }}
            </span>
              : {{ totalRows }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!-- modal -->
    <b-modal
      id="delete-modal"
      ref="delete-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_delete_this_item') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="danger"
          @click="confirmDelete"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
    <!-- modal -->
    <b-modal
      id="change-status-modal"
      ref="change-status-modal"
      modal-class="modal-primary"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_change_status') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="confirmChangeStatus"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
    <!-- modal -->
    <b-modal
      id="errors-modal"
      ref="errors-modal"
      modal-class="modal-danger"
      centered
      :title="$t('errors messages')"
    >
      <b-card-text v-if="errorsFileObjects">
        <app-collapse
          v-for="(item,index) in errorsFileObjects"
          :key="index"
        >
          <app-collapse-item
            v-for="(errors, key) in item"
            :key="key"
            :is-visible="index === 0 ? true : false"
            :title="$t('general.field_lang', { field: $t('row')+'  '+getTitle(key)})"
          >
            <b-alert
              v-for="(error,k) in errors"
              :key="k"
              variant="danger"
              show
            >
              <div class="alert-body">
                <span>{{ error[0] }}</span>
              </div>
            </b-alert>
          </app-collapse-item>
        </app-collapse>
      </b-card-text>
      <b-card-text v-if="errorsFileString">
        <app-collapse>
          <app-collapse-item
            :is-visible="true"
            :title="$t('errors messages')"
          >
            <b-alert
              v-for="(error,index) in errorsFileString"
              :key="index"
              variant="danger"
              show
            >
              <div class="alert-body">
                <span>{{ error }}</span>
              </div>
            </b-alert>
          </app-collapse-item>
        </app-collapse>
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="competition-modal"
      ref="competition-modal"
      modal-class="modal-primary"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_add_competition_match') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="confirmAddCompetiton"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
<!--    <Loader :loading="loading" />-->


  </div>
</template>

<script>
import { BAlert, BDropdownItemButton } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'TableIndex',
  components: {
    Loader,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BDropdownItemButton,
  },
  props: {
    hasExport: {
      type: String,
      default: '',
    },
    TabBusy: {
      type: Boolean,
      default() {
        return false
      },
    },
    hasImport: {
      type: String,
      default: '',
    },
    hasImportMulti: {
      type: String,
      default: '',
    },
    hasExportMulti: {
      type: String,
      default: '',
    },
    sortBy: {
      type: String,
      default: 'id',
    },
    sortDir: {
      type: Boolean,
      default: true,
    },
    singularName: {
      type: String,
      required: true,
    },
    canChangeStatus: {
      type: Boolean,
      default() {
        return false
      },

    },

      hasCompetition: {
        type: Boolean,
        default() {
          return false
        },
      },
    customChangeStatus: {
      type: Boolean,
      default() {
        return false
      },
    },
    statuses: {
      type: Array,
      default() {
        return ['active', 'inactive']
      },
    },
    resourceName: {
      type: String,
      required: true,
    },
    addRoute: {
      type: Object,
      default() {
        return {
          name: `add-${this.singularName.toLocaleLowerCase()}`,
        }
      },
    },
    editRoute: {
      type: String,
      default() {
        return `edit-${this.singularName.toLocaleLowerCase()}`
      },
    },
    filterFields: {
      type: Object,
      default() {
        return {}
      },
    },
    filterMultiple: {
      type: Object,
      default() {
        return {}
      },
    },
    customUpdateObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      generalActions: {
        can_export: false,
        can_import: false,
      },
      headersList: [],
      items: [],
      isSortDirDesc: this.sortDir,
      selectedItem: null,
      selectedFile: null,
      errorsFileObjects: null,
      errorsFileString: null,
      isBusy: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        active: 'light-success',
        enabled: 'light-success',
        inactive: 'light-danger',
        Rejected: 'light-danger',
        disabled: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    addLabel() {
      return `${this.$t('general.add_model', { models: this.$t(`${this.singularName.replace(/-/g, ' ')}`) })}`
    },
    fields() {
      const headers = this.headersList
      headers.forEach((part, index) => {
        if (['actions'].includes(part.key)) {
          headers[index] = {
            ...part,
            class: 'col-small',
          }
        }
      }, headers)
      return headers
    },
    statusLabels() {
      return {
        active: this.$t('statuses.active'),
        inactive: this.$t('statuses.inactive'),
      }
    },
    slotCustomActions() {
      return this.$scopedSlots.custom_actions
    },
  },
  watch: {
    currentPage() {
      this.getData()
    },
    perPage() {
      this.getData()
    },
    filterFields: {
      deep: true,
      handler() {
        this.getData()
      },
    },
    customUpdateObject: {
      handler(val) {
        const $idx = this.items.findIndex(el => el.id === val.id)
        this.items[$idx].status = val.status
        this.items[$idx].status_value = val.status_value
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    checkArrayType(arr) {
      let containsObjects = false
      let containsStrings = false
      for (const element of arr) {
        if (typeof element === 'object') {
          containsObjects = true
        }
        if (typeof element === 'string') {
          containsStrings = true
        }
      }
      return {
        containsObjects,
        containsStrings,
      }
    },
    getTitle(key) {
      const extractedPart = key.slice('raw_'.length)
      return extractedPart
    },
    handleFileUpload(event, key) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]
      if (this.selectedFile) {
        this.loading = true;
        const formData = new FormData()
        formData.append('file', this.selectedFile)
        this.axios
          .post(`/import/${key}`, formData)
          .then(response => {
            this.$refs['errors-modal'].hide()
            if (response.status === 200) {
              this.$bvToast.toast(response.data.message, {
                title: this.$t('Success'),
                variant: 'success',
                solid: true,
              })
              this.selectedFile = null
              this.errorsFileString = null
              this.errorsFileObjects = null
            }
          })

          .catch(error => {
            if (error.response?.data?.errors) {
              this.$refs['errors-modal'].show()
              if (error.response?.data?.errors && this.checkArrayType(error.response?.data?.errors).containsObjects) {
                this.errorsFileObjects = error.response?.data?.errors
                this.errorsFileString = null
              } else {
                this.errorsFileString = error.response?.data?.errors
                this.errorsFileObjects = null
              }
              this.$bvToast.toast(error.response.data.message, {
                title: this.$t('Error'),
                variant: 'danger',
                solid: true,
              })
            }
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    downloadFileOnClick(name) {
      this.loading = true;
      this.axios
        .get(`/import/download/${name}/template`)
        .then(response => {
          const fileUrl = response.data?.data?.file

          const link = document.createElement('a')
          link.href = fileUrl
          const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => {
          this.loading = false; // Set loading back to false after the API call completes
        })

    },
    getData() {
      this.isBusy = true;
      return new Promise((resolve, reject) => {
        const params = {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_dir: this.isSortDirDesc ? 'desc' : 'asc',
          ...this.filterFields,
        }
        this.axios
          .get(`/${this.resourceName}`, { params })
          .then(response => {
            this.items = response.data.data
            this.totalRows = response.data.meta.total
            this.generalActions = {
              ...this.generalActions,
              ...response.data.actions,
            }
            this.headersList = response.data.headers
            resolve(response)
          })

          .catch(error => reject(error))
          .finally(() => {
            this.isBusy = false; // Set loading back to false after the API call completes
          })

      })
    },
    showDeleteModal(item) {
      this.selectedItem = item
      this.$refs['delete-modal'].show()
    },
    confirmDelete() {
      this.loading = true;
      const item = this.selectedItem
      const route = `/${this.resourceName}/${this.selectedItem.id}`
      this.axios
        .delete(route)
        .then(res => {
          this.$refs['delete-modal'].hide()
          const $idx = this.items.indexOf(item)
          this.items.splice($idx, 1)
          this.selectedItem = null
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(error => {
          this.$refs['delete-modal'].hide()
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
        })
    },
    changeStatus($e, item) {
      this.$refs['change-status-modal'].show()
      this.selectedItem = item
    },
    addCompetition($e, item) {
      this.$refs['competition-modal'].show()
      this.selectedItem = item
    },
    confirmAddCompetiton() {
      this.loading = true;
      this.$refs['competition-modal'].hide()
      const $item = this.selectedItem
      const $idx = this.items.indexOf($item)
      const $link = `/matches/competitions/${$item.id}`
      const $competition = $item.has_competition ? 'no' : 'yes'
      this.axios
        .put($link, {
          has_competition: $competition,
        })
        .then(res => {
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
          })
          console.log(this.items[$idx].has_competition)
          this.items[$idx].has_competition = !this.items[$idx].has_competition

          // window.location.reload()
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },

    confirmChangeStatus() {
      this.loading = true;
      this.$refs['change-status-modal'].hide()
      const $item = this.selectedItem
      const $idx = this.items.indexOf($item)
      const statusIdx = this.statuses.indexOf($item.status) === 0 ? 1 : 0
      const toStatus = this.statuses[statusIdx]
      const $statusType = {
        status: toStatus,
      }
      const $link = `/${this.resourceName}/status/${$item.id}`
      this.axios
        .patch($link, $statusType)
        .then(res => {
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
          })
          this.items[$idx].status = toStatus
          this.items[$idx].status_value = this.statusLabels[toStatus]

          this.selectedItem = null
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    exportTable() {
      this.loading = true;
      const route = `${this.resourceName}/export`
      this.axios
        .post(route, { responseType: 'blob' })
        .then(res => {
          if (res.data) {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(
              new Blob([res.data]),
            )
            link.setAttribute('download', 'data.csv')
            document.body.appendChild(link)
            link.click()
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(error => {
          this.$bvToast.toast(error.response?.data?.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.col-small {
  width: 180px;
}

.col-w-100 {
  width: 200px;
}

.switch-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

</style>
<style lang="scss" >
.per-page-selector {
  width: 90px;
}
.dropdown-item:hover,
.dropdown-item:hover label{
  cursor: pointer;
}
.dropdown-item label {
  margin: 0 !important;
}
.dropdown-menu.show .dropdown-item {
  width: 100% !important;
}
</style>
