<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Date Range">
            <b-row>
              <b-col lg="3" md="6" sm="12">
                <b-form-datepicker
                  v-model="searchFields.startDate"
                  @input="e => inputChange(e, 'startDate')"
                  placeholder="Start Date"
                  show-time
                />
              </b-col>
              <b-col lg="3" md="6" sm="12">
                <TimePicker
                  v-model="searchFields.startTime"
                  @input="e => inputChange(e, 'startTime')"
                  placeholder="Start time"
                />
              </b-col>
              <!-- End Date -->
              <b-col lg="3" md="6" sm="12">
                <b-form-datepicker
                  v-model="searchFields.endDate"
                  @input="e => inputChange(e, 'endDate')"
                  placeholder="End Date"
                  show-time
                />
              </b-col>
              <b-col lg="3" md="6" sm="12">
                <TimePicker
                  v-model="searchFields.endTime"
                  @input="e => inputChange(e, 'endTime')"
                  placeholder="End time"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'customer-points-center',
      resourceName: 'customer-points-center',
      singularName: 'customer-points-center',
      searchFields: {
        title: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },

}
</script>