<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.title')"
              label-for="title"
            >
              <b-form-input
                id="title"
                :value="searchFields.title"
                :placeholder="$t('labels.title')"
                @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.match_type') }}</label>
            <v-select
              v-model="searchFields.match_type"
              :dir="$store.state.appConfig.layout.direction"
              label="label"
              :options="match_types"
              :reduce="item => item.value"
              :placeholder="$t('labels.match_type')"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.start_at') }}</label>
            <DatePicker
              v-model="searchFields.start_at"

              @input="inputChange($event, 'start_at')"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :can-change-status="canChangeStatus"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :has-competition="hasCompetition"
    >

    </TableIndex>

    <!-- modal -->
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],

  data() {
    return {
      resourceName: 'matches',
      singularName: 'match',
      hasCompetition:true,
      customField: 'competition',
      canChangeStatus: false,
      match_types: [],
      selectedItem: null,
      searchFields: {
        title: null,
        match_type: null,
        status: null,
        start_at: null,
      },
    }
  },
  created() {
    this.getMatchTypes()
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e

    }, 500),
    getMatchTypes() {
      this.axios.get('/lists/match/types')
        .then(res => {
          this.match_types = res.data
        })
    },


  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 'Not Started',
          name: this.$t('statuses.not_started'),
        },
        {
          id: 'Match Cancelled',
          name: this.$t('statuses.match_cancelled'),
        },
        {
          id: 'Match Finished',
          name: this.$t('statuses.match_finished'),
        },
        {
          id: 'Time to be defined',
          name: this.$t('statuses.time_to_be_defined'),
        },
        {
          id: 'Match Postponed',
          name: this.$t('statuses.match_postponed'),
        },
        {
          id: 'Halftime',
          name: this.$t('statuses.halftime'),
        },
      ]
    },
  },
}
</script>